<template>
  <div class="vh-100 outer screen" style="text-align: center">
    <div class="container center">
      <CountdownCounter v-if="date_has_prize_draw && countdownTime" />
      <WinnerSlider v-if="date_has_prize_draw && !countdownTime" />
      <InactiveComponent v-if="!date_has_prize_draw" />
      <PrizeButtonBar current="draw" />
    </div>
  </div>
</template>

<script>
import CountdownMixin from "@/mixins/CountdownMixin";
import CountdownCounter from "@/components/CountdownCounter.vue";
import WinnerSlider from "@/components/WinnerSlider.vue";
import InactiveComponent from "@/components/InactiveComponent.vue";
import PrizeButtonBar from "@/components/PrizeButtonBar.vue";

export default {
  mixins: [CountdownMixin],
  created() {
    this.$store.dispatch("fetchWinners");
    const serverTimeUrl = "https://sendcloudholidaygifts.com/get_time/"; // Replace with your server's endpoint
    this.startCountdown(serverTimeUrl);
  },
  methods: {
    sendToWinners() {
      this.$router.push("/winners");
    },
    sendToHome() {
      this.$router.push("/");
    },
  },
  components: {
    CountdownCounter,
    WinnerSlider,
    InactiveComponent,
    PrizeButtonBar,
  },
};
</script>
