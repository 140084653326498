<template>
  <div class="vh-100 outer screen" style="text-align: center">
    <div class="container center">
      <div class="row">
        <div class="col-md-6 offset-md-3 transparentbox pt-3 pb-4">
          <h2 class="text-light">Sign up 🔑</h2>
          <h4 class="text-light">Please enter your personal data</h4>
          <input
            v-model="first_name"
            class="form-control field no-focusborder form-control-lg mb-3"
            type="text"
            ref="inputfield"
            placeholder="First name"
            required
          />
          <input
            v-model="last_name"
            class="form-control field no-focusborder form-control-lg mb-3"
            type="text"
            ref="inputfield"
            placeholder="Last name"
            required
          />
          <!-- <div class="input-group mb-3">
            <input
              v-model="email_prefix"
              type="text"
              class="form-control form-control-lg"
              placeholder="Email prefix"
              aria-describedby="basic-addon2"
            />
            <span class="input-group-text" id="basic-addon2">
              @sendcloud.com
            </span>
          </div> -->

          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control form-control-lg"
              aria-label="Text input with dropdown button"
              v-model="email_prefix"
              placeholder="Email prefix"
            />
            <button
              class="btn btn-primary dropdown-toggle btn-lg"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ email_sufffix }}
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="selectDropdownItem('@sendcloud.com')"
                  active="active"
                >
                  @sendcloud.com
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="selectDropdownItem('@loxsolution.com')"
                >
                  @loxsolution.com
                </a>
              </li>
            </ul>
          </div>

          <div class="d-grid gap-2">
            <button
              type="button"
              class="btn btn-primary text-light btn-lg"
              :disabled="isDisabled == true"
              @click="createEmployee()"
            >
              Sign up
            </button>
          </div>
        </div>
        <div class="col-md-6 offset-md-3">
          <div class="d-grid gap-2">
            <button
              type="button"
              class="btn btn-secondary mt-3"
              @click="goToHome()"
            >
              Home 🏠
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SignUpView",
  components: {},
  data: () => ({
    email_prefix: null,
    email_sufffix: "@sendcloud.com",
    first_name: null,
    last_name: null,
  }),
  computed: {
    code() {
      return this.$store.getters.getCode;
    },
    isEmail() {
      if (this.email == null) {
        return true;
      }
      if (this.email) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          return true;
        }
      }
      return false;
    },
    isDisabled() {
      if (this.first_name && this.last_name && this.email_prefix) {
        return false;
      }
      return true;
    },
    validateEmail() {
      let msg;
      if (this.email !== null) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          msg = "";
        } else {
          msg = "Please enter a valid email address";
        }
      }
      return msg;
    },
  },
  created() {
    if (!this.code) {
      this.$router.push("/");
    }
  },
  methods: {
    createEmployee() {
      let data = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email_prefix.toLowerCase() + this.email_sufffix,
        code: this.code.toUpperCase(),
        _state: 1,
      };

      // Convert the data object into query parameters
      const queryParams = new URLSearchParams(data).toString();

      // Make the GET request with the data as query parameters
      axios
        .get(`https://sendcloudholidaygifts.com/create_employee?${queryParams}`)
        .then((response) => {
          // Handle response here
          console.log(response);
          this.$store.commit("setEmployee", response.data);
          this.$router.push("/prizes");
        })
        .catch(() => {
          this.$router.push("/");
        });
    },
    goToHome() {
      this.$router.push("/");
    },
    selectDropdownItem(item) {
      this.email_sufffix = item; // Update the selected dropdown item
    },
  },
};
</script>

<style>
/* img {
  padding-bottom: 20px;
} */
.outer {
  display: flex;
  align-items: center;
}
.center {
  margin: auto;
  padding: 10px;
}
</style>
