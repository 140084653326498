export default {
  data() {
    return {
      showWinnersTime: 12,
      showTimerTime: 14,
      countdown: null, // Time remaining in hh:mm:ss format
      timer: null, // Interval timer reference
      serverDate: null, // Last fetched server time
      localOffset: 0, // Difference between local and server time in milliseconds
      countdownTime: true, // Controls component visibility
      date_prize_draws: [
        "09/12/2024",
        "10/12/2024",
        "11/12/2024",
        "12/12/2024",
        "13/12/2024",
        "16/12/2024",
        "17/12/2024",
        "18/12/2024",
        "19/12/2024",
        "20/12/2024",
      ], // Prize draw dates in DD/MM/YYYY format
      date_has_prize_draw: false, // Indicates if today has a prize draw
    };
  },
  methods: {
    async fetchServerTime(url) {
      try {
        const response = await fetch(url);
        const serverTime = await response.json(); // Assume the API returns time in ISO 8601 format
        const serverDate = new Date(serverTime.time); // Convert to Date object
        // Calculate the offset between server time and local time
        this.localOffset = serverDate - new Date();
        return serverDate;
      } catch (error) {
        console.error("Error fetching server time:", error);
        return null;
      }
    },
    calculateTimeToNoon(currentDate) {
      const noon = new Date(currentDate);
      noon.setHours(this.showWinnersTime, 0, 0, 0); // Set the time to 12:00:00

      // If noon has already passed, calculate for the next day
      if (currentDate > noon) {
        noon.setDate(noon.getDate() + 1);
      }

      const diffMs = noon - currentDate;
      const hours = String(
        Math.floor((diffMs / (1000 * 60 * 60)) % 24)
      ).padStart(2, "0");
      const minutes = String(Math.floor((diffMs / (1000 * 60)) % 60)).padStart(
        2,
        "0"
      );
      const seconds = String(Math.floor((diffMs / 1000) % 60)).padStart(2, "0");

      return `${hours}:${minutes}:${seconds}`;
    },
    checkVisibility(currentDate) {
      const amsterdamTime = new Date(currentDate.getTime() + this.localOffset);
      const hours = amsterdamTime.getHours();

      // Hide component from 12:00 to 14:00
      this.countdownTime = !(
        hours >= this.showWinnersTime && hours < this.showTimerTime
      );
    },
    async startCountdown(url) {
      // Fetch initial server time
      this.serverDate = await this.fetchServerTime(url);
      if (!this.serverDate) return;

      this.updateCountdown();
      this.checkVisibility(new Date());
      this.checkPrizeDraw(new Date());

      // Refetch server time every minute
      setInterval(async () => {
        this.serverDate = await this.fetchServerTime(url);
      }, 60000); // 60,000 ms = 1 minute

      // Update countdown, visibility, and prize draw status every second
      this.timer = setInterval(() => {
        const now = new Date(new Date().getTime() + this.localOffset); // Adjust local time with offset
        this.updateCountdown(now);
        this.checkVisibility(now);
        this.checkPrizeDraw(now);
      }, 1000); // 1 second
    },
    updateCountdown(currentDate = new Date()) {
      this.countdown = this.calculateTimeToNoon(currentDate);
    },
    stopCountdown() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    checkPrizeDraw(currentDate) {
      const adjustedDate = new Date(currentDate.getTime() + this.localOffset);
      const formattedDate = adjustedDate
        .toLocaleDateString("en-GB") // Format as DD/MM/YYYY
        .replace(/\//g, "/");
      this.date_has_prize_draw = this.date_prize_draws.includes(formattedDate);
    },
  },
  beforeDestroy() {
    this.stopCountdown(); // Clear timer when component is destroyed
  },
};
