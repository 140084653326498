<template lang="html">
  <div class="vh-100 outer screen" style="text-align: center">
    <div class="container center">
      <div class="row">
        <div class="col-md-8 offset-md-2 transparentbox padding-top pb-3">
          <h1 class="text-light">Ho ho ho {{ getEmployee.first_name }},</h1>
          <h2 class="text-light mb-3">We hope you win a prize</h2>
          <img
            class="responsive-img"
            src="@/assets/giphy.gif"
            alt=""
            style="max-width: 480px"
          />
          <h2 class="text-light mt-3">
            Now all you have to do is <br />
            sit back and keep an eye on the draw page!
          </h2>
          <div class="d-grid gap-2">
            <button
              type="button"
              class="btn btn-secondary btn-lg mb-3"
              @click="sendToDraw()"
            >
              Prize draw page 🏆
            </button>
          </div>
          <div class="d-grid gap-2">
            <button
              type="button"
              class="btn btn-primary btn-lg mb-3"
              @click="sendToHome()"
            >
              Home 🏠
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThankYouView",
  data: () => ({}),
  computed: {
    getEmployee() {
      return this.$store.getters.getEmployee;
    },
    getPrize() {
      let prizes = this.$store.getters.getPrizes;
      let rPrize = null;
      prizes.forEach((prize) => {
        if (this.getEmployee.choice == prize.number) {
          rPrize = prize;
        }
      });
      return rPrize;
    },
  },
  methods: {
    sendToDraw() {
      this.$router.push("/draw");
    },
    sendToHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="css" scoped></style>
