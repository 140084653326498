<template>
  <div class="screen" style="text-align: center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2 transparentbox mt-5 mb-3">
          <img
            src="@/assets/logo.png"
            alt="Sendcloud"
            class="responsive-img"
            style="max-width: 500px"
          />
          <h1 class="display-3 text-light">{{ getTitle }}</h1>
          <span v-html="getLead" class="text-light" v-if="!isLoading"></span>
          <div class="d-grid gap-2">
            <button
              type="button"
              class="btn btn-primary text-info mb-3"
              @click="sendToCodeVerification()"
            >
              Verify my code 🔑
            </button>
          </div>
          <span
            v-html="getLeadInfo"
            class="text-light"
            v-if="!isLoading"
          ></span>
          <div class="d-grid gap-2">
            <button
              type="button"
              class="btn btn-secondary mb-3"
              @click="sendToDraw()"
            >
              Prize draw! 🏆
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  computed: {
    isLoading() {
      return this.$store.getters.getLoading;
    },
    hasEmail: function () {
      if (this.$store.getters.getEmail === "") {
        return false;
      }
      return true;
    },
    hasCode: function () {
      if (this.$store.getters.getCode === "") {
        return false;
      }
      return true;
    },
    getTitle() {
      let texts = this.$store.getters.getTexts;
      return texts.home_title;
    },
    getLead() {
      let texts = this.$store.getters.getTexts;
      return texts.home_lead;
    },
    getLeadInfo() {
      let texts = this.$store.getters.getTexts;
      return texts.home_lead_info;
    },
  },
  created() {
    this.$store.dispatch("fetchAppTexts");
    this.$store.dispatch("fetchPrizes");
  },
  methods: {
    sendToCodeVerification() {
      this.$router.push("/verify");
    },
    sendToDraw() {
      this.$router.push("/draw");
    },
  },
};
</script>

<style>
.outer {
  display: flex;
  align-items: center;
}
.center {
  margin: auto;
  padding: 10px;
}
.logo {
  padding: 15px;
}
.bottom-spacer {
  padding: 100px;
}
</style>
