<template lang="html">
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    v-if="prize && getEmployee"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1
            class="modal-title fs-5"
            id="exampleModalLabel"
            v-html="prize.name"
          ></h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div
                  v-if="typeof prize.image != 'undefined'"
                  class="ratio ratio-4x3"
                >
                  <img
                    v-if="prize.image.path !== null"
                    :src="
                      'https://db.sendcloudholidaygifts.com/storage/uploads/' +
                      prize.image.path
                    "
                    class="card-img-top"
                    alt="..."
                    style="object-fit: cover"
                  />
                </div>
              </div>
              <div class="col-md-6 ms-auto">
                <span v-html="prize.description"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-primary col"
            @click="AddRemovePrize(prize.prize_key_name)"
            :disabled="!prize.is_selectable"
          >
            I'd like to win this prize!
            <span
              v-if="getEmployee.chosen.includes(prize.prize_key_name)"
              class="badge text-bg-light"
            >
              Selected
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrizeDetail",
  props: ["prize"],
  data: () => ({}),
  created() {
    if (!this.getEmployee) {
      this.$router.push("/");
    }
  },
  computed: {
    getEmployee() {
      return this.$store.getters.getEmployee;
    },
    getChosen() {
      return this.$store.getters.getChosen;
    },
  },
  methods: {
    AddRemovePrize(prize_key_name) {
      console.log(prize_key_name);

      const index = this.getChosen.indexOf(prize_key_name);
      let updatedChosen = [...this.getChosen]; // Copy the array

      if (index === -1) {
        // Item not found, add it
        updatedChosen.push(prize_key_name);
      } else {
        // Item found, remove it
        updatedChosen.splice(index, 1);
      }

      // Update Vuex store with the modified array
      this.$store.commit("updateChosen", updatedChosen);

      // Optionally update employee's 'chosen' property
      let employee = this.getEmployee;
      employee.chosen = updatedChosen; // Ensure reactivity
      this.$store.dispatch("saveEmployee", employee);
    },
  },
};
</script>
