<template>
  <span v-html="formatDateToDutch(date)"></span>
</template>

<script>
export default {
  name: "DateComponent",
  props: {
    date: String,
  },
  methods: {
    formatDateToDutch(dateString) {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const monthsOfYear = [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
      ];

      // Maak een Date-object van de string
      const date = new Date(dateString);

      // Haal de dag, maand en jaar op
      const dayOfWeek = daysOfWeek[date.getDay()];
      const day = date.getDate();
      const month = monthsOfYear[date.getMonth()];
      // const year = date.getFullYear();

      // Combineer alles in het gewenste formaat
      // return `${dayOfWeek} ${day} ${month} ${year}`;
      return `${dayOfWeek} ${day} ${month}`;
    },
  },
};
</script>
