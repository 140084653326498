<template>
  <div class="d-flex justify-content-center align-items-center mt-3 mb-3">
    <button
      v-if="current != 'home'"
      type="button"
      class="btn btn-secondary text-center me-3"
      @click="goToHome"
    >
      Home 🏠
    </button>
    <button
      v-if="current != 'draw'"
      class="btn btn-primary text-center me-3"
      @click="goToDraw()"
    >
      Draw page🏆
    </button>
    <button
      v-if="current != 'winners'"
      class="btn btn-primary text-center me-3"
      @click="goToWinners()"
    >
      Winners page 🏆
    </button>
  </div>
</template>

<script>
export default {
  name: "PrizeButtonBar",
  props: {
    current: String,
  },
  methods: {
    goToHome() {
      this.$router.push("/");
    },
    goToDraw() {
      this.$router.push("/draw");
    },
    goToWinners() {
      this.$router.push("/winners");
    },
    goToCheckOut() {
      this.$router.push("/checkout");
    },
    goToPrizes() {
      this.$router.push("/prizes");
    },
    goToConsolationPrizes() {
      this.$router.push("/consolation");
    },
    goToIntro() {
      this.$router.push("/intro");
    },
  },
};
</script>
