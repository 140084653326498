<template>
  <div class="vh-100 outer screen" style="text-align: center">
    <div class="container center">
      <div class="row">
        <div class="col-md-6 offset-md-3 transparentbox pb-3 pt-3">
          <h2 class="text-light">Sign in 🔑</h2>
          <h4 class="text-light">Please enter your code</h4>
          <input
            v-model="email"
            class="form-control field no-focusborder form-control-lg mb-3"
            :class="[isEmail ? '' : 'is-invalid']"
            type="email"
            ref="inputfield"
            placeholder="Email address"
            required
          />
          <input
            v-model="code"
            class="form-control field no-focusborder text-uppercase form-control-lg mb-3"
            type="text"
            ref="inputfield"
            placeholder="Code"
            maxlength="6"
            required
          />
          <div class="d-grid gap-2">
            <button
              type="button"
              class="btn btn-primary text-light btn-lg mb-3"
              :disabled="isDisabled == true"
              @click="getEmployee()"
            >
              Login
            </button>
          </div>
        </div>
        <div class="col-md-6 offset-md-3">
          <div class="d-grid gap-2">
            <button
              type="button"
              class="btn btn-secondary mt-3"
              @click="goToHome()"
            >
              Home 🏠
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  >
</template>

<script>
export default {
  name: "SignInView",
  components: {},
  data: () => ({
    email: null,
  }),
  created() {
    if (!this.code) {
      this.$router.push("/");
    }
  },
  computed: {
    code() {
      return this.$store.getters.getCode;
    },
    isEmail() {
      if (this.email == null) {
        return true;
      }
      if (this.email) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          return true;
        }
      }
      return false;
    },
    isDisabled() {
      if (this.code) {
        if (
          // /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email) &&
          this.code.length == 6
        ) {
          return false;
        }
      }
      return true;
    },
    validateEmail() {
      let msg;
      if (this.email !== null) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          msg = "";
        } else {
          msg = "Please enter a valid email address";
        }
      }
      return msg;
    },
  },
  methods: {
    getEmployee() {
      let data = {};
      data.email = this.email.toLowerCase();
      data.code = this.code.toUpperCase();
      this.$store.dispatch("getEmployee", data);
    },
    goToHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style>
/* img {
  padding-bottom: 20px;
} */
.outer {
  display: flex;
  align-items: center;
}
.center {
  margin: auto;
  padding: 10px;
}
</style>
