<template>
  <h2 class="text-info display-5">Todays Winners!</h2>
  <div class="col-md-8 offset-md-2 transparentbox mb-3">
    <!-- <ConfettiComponent ref="ConfettiComponent" /> -->

    <h2 class="text-primary display-6">
      {{ getCurrentWinners.prize.name }}
    </h2>
    <div class="fading-slider">
      <transition name="fade" mode="out-in">
        <div :key="currentIndex" class="slider-item">
          <h1 class="text-light display-2">
            {{ getCurrentWinners.winner_names[currentIndex] }}
          </h1>
        </div>
      </transition>
    </div>
  </div>

  <div class="col-md-8 offset-md-2 transparentbox mb-3">
    <div v-if="getCurrentConsolationWinners" class="mb-3">
      <h5 class="text-primary display-6">
        {{ getCurrentConsolationWinners.consolation_prize.name }}
      </h5>
      <span
        class="text-light display-6"
        v-for="(winnername, index) in getCurrentConsolationWinners.winner_names"
        v-bind:key="winnername"
      >
        {{ winnername
        }}<span
          v-if="index < getCurrentConsolationWinners.winner_names.length - 1"
          >,
        </span>
      </span>
    </div>
  </div>
</template>

<script>
// import ConfettiComponent from "./ConfettiComponent.vue";

export default {
  name: "WinnerSlider",
  components: {
    // ConfettiComponent,
  },
  created() {
    this.$store.dispatch("fetchWinners");
  },
  computed: {
    getCurrentWinners() {
      let winners = this.$store.getters.getWinners;
      return winners.find(
        (winner) => winner.winner_state === "current" && winner.prize !== null
      );
    },
    getCurrentConsolationWinners() {
      let winners = this.$store.getters.getWinners;
      return winners.find(
        (winner) =>
          winner.winner_state === "current" && winner.consolation_prize !== null
      );
    },
  },
  data() {
    return {
      currentIndex: 0, // Tracks the current item
    };
  },
  mounted() {
    this.startSlideshow();
    // this.$refs.ConfettiComponent.startConfetti();
  },
  beforeUnmount() {
    clearInterval(this.interval); // Clear interval when the component is destroyed
  },
  methods: {
    startSlideshow() {
      this.interval = setInterval(() => {
        this.currentIndex =
          (this.currentIndex + 1) % this.getCurrentWinners.winner_names.length; // Loop through items
      }, 3000); // Change item every 3 seconds
    },
  },
};
</script>

<style>
.fading-slider {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 20px;
  height: 50px; /* Adjust to match content */
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-item {
  display: inline-block;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
