<template>
  <PrizeDetail :prize="modal_prize" />
  <LightBoxModal :prize="modal_prize" />
  <div class="container" v-if="getEmployee">
    <div class="row">
      <div
        class="col-lg-10 offset-lg-1 col-md-12 offset-md-0 transparentbox mt-5 pb-4 pt-4"
      >
        <h1 class="text-light text-center">Raffle Gifts 🎁</h1>

        <ButtonBar current="prizes" />

        <div
          class="row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-4"
        >
          <div class="col" v-for="prize in getPrizes" v-bind:key="prize">
            <div class="card h-100">
              <div class="ratio ratio-4x3">
                <img
                  v-if="prize.image.path !== null"
                  :src="
                    'https://db.sendcloudholidaygifts.com/storage/uploads/' +
                    prize.image.path
                  "
                  class="card-img-top"
                  alt="..."
                  style="object-fit: cover"
                />
              </div>

              <div class="card-body pb-1">
                <div class="draw-date text-center">
                  <DateComponent :date="prize.draw_date" />
                </div>
                <h5 class="card-title text-center headline">
                  {{ prize.name }}
                </h5>
              </div>
              <div class="card-footer">
                <div class="d-grid gap-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    @click="populateModal(prize)"
                    :disabled="!prize.is_selectable"
                  >
                    View Prize
                    <span
                      v-if="getEmployee.chosen.includes(prize.prize_key_name)"
                      class="badge text-bg-light"
                    >
                      Selected
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PrizeButtonBar current="consolation" />
    </div>
  </div>
</template>

<script>
import PrizeDetail from "@/components/PrizeDetail.vue";
import LightBoxModal from "@/components/LightBoxModal.vue";
import ButtonBar from "@/components/ButtonBar.vue";
import DateComponent from "@/components/DateComponent.vue";
import PrizeButtonBar from "@/components/PrizeButtonBar.vue";

export default {
  name: "PrizesView",
  created() {
    this.$store.dispatch("fetchPrizes");
    this.populateModal(this.getPrizes[0]);
  },
  data: () => ({
    modal_prize: {},
  }),
  components: {
    PrizeDetail,
    LightBoxModal,
    ButtonBar,
    DateComponent,
    PrizeButtonBar,
  },
  computed: {
    getPrizes() {
      return this.$store.getters.getPrizes;
    },
    getEmployee() {
      return this.$store.getters.getEmployee;
    },
    getEmployeeIntro() {
      let texts = this.$store.getters.getTexts;
      return texts.employee_introduction;
    },
  },
  methods: {
    populateModal(prize) {
      this.modal_prize = prize;
    },
    goToCheckOut() {
      this.$router.push("/checkout");
    },
    goToIntro() {
      this.$router.push("/intro");
    },
  },
};
</script>

<style>
.outer {
  display: flex;
  align-items: center;
}
.center {
  margin: auto;
  padding: 10px;
}
.card {
  border: none;
}
.fadetext {
  height: 50px !important;
  overflow: hidden !important;
  -webkit-mask-image: linear-gradient(180deg, #000 0%, transparent);
  mask-image: linear-gradient(180deg, #000 0%, transparent);
}
.card-body {
  z-index: 1000;
}
.draw-date {
  font-weight: 800;
  color: #c34128;
}
</style>
