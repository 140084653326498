<template lang="html">
  <div class="vh-100 outer screen" style="text-align: center">
    <div class="container center">
      <div class="row">
        <h2 class="text-info display-5">Previous Winners 🏆</h2>
        <div class="col-md-8 offset-md-2 transparentbox mb-3 pb-4 pb-3">
          <!-- Prizes -->
          <h2
            class="text-light display-4"
            v-if="
              !getPreviousPrizeWinners.length > 0 &&
              !getPreviousConsolationPrizeWinners.length > 0
            "
          >
            No winners yet!
          </h2>

          <h2 class="text-light" v-if="getPreviousPrizeWinners.length > 0">
            Raffle gifts
          </h2>

          <div
            class="row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-4"
            v-if="getPreviousPrizeWinners"
          >
            <div
              class="col"
              v-for="winners in getPreviousPrizeWinners"
              v-bind:key="winners"
            >
              <div class="card h-100">
                <div class="ratio ratio-4x3">
                  <img
                    v-if="winners.prize.image.path !== null"
                    :src="
                      'https://db.sendcloudholidaygifts.com/storage/uploads/' +
                      winners.prize.image.path
                    "
                    class="card-img-top"
                    alt="..."
                    style="object-fit: cover"
                  />
                </div>
                <div class="card-body pb-3">
                  <div class="draw-date text-center">
                    <DateComponent :date="winners.prize.draw_date" />
                  </div>
                  <h5 class="card-title text-center headline">
                    {{ winners.prize.name }}
                  </h5>
                  <span
                    v-for="(winnername, index) in winners.winner_names"
                    v-bind:key="winnername"
                  >
                    {{ winnername
                    }}<span v-if="index < winners.winner_names.length - 1"
                      >,&nbsp;</span
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-md-8 offset-md-2 transparentbox mb-3 pb-4 pb-3"
          v-if="getPreviousConsolationPrizeWinners.length > 0"
        >
          <!-- Consolation Prizes -->
          <h2 class="text-light">Consolation gifts</h2>
          <div
            class="row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-4"
            v-if="getPreviousPrizeWinners"
          >
            <div
              class="col"
              v-for="winners in getPreviousConsolationPrizeWinners"
              v-bind:key="winners"
            >
              <div class="card h-100">
                <div class="ratio ratio-4x3">
                  <img
                    v-if="winners.consolation_prize.image.path !== null"
                    :src="
                      'https://db.sendcloudholidaygifts.com/storage/uploads/' +
                      winners.consolation_prize.image.path
                    "
                    class="card-img-top"
                    alt="..."
                    style="object-fit: cover"
                  />
                </div>
                <div class="card-body pb-3">
                  <div class="draw-date text-center">
                    <DateComponent
                      :date="winners.consolation_prize.draw_date"
                    />
                  </div>
                  <h5 class="card-title text-center headline">
                    {{ winners.consolation_prize.name }}
                  </h5>
                  <span
                    v-for="(winnername, index) in winners.winner_names"
                    v-bind:key="winnername"
                  >
                    {{ winnername
                    }}<span v-if="index < winners.winner_names.length - 1"
                      >,&nbsp;</span
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PrizeButtonBar current="winners" />
      </div>
    </div>
  </div>
</template>

<script>
import DateComponent from "@/components/DateComponent.vue";
import PrizeButtonBar from "@/components/PrizeButtonBar.vue";

export default {
  name: "WinnersView",
  computed: {
    getPreviousPrizeWinners() {
      let winners = this.$store.getters.getWinners;
      return winners.filter(
        (winner) => winner.winner_state === "previous" && winner.prize != null
      );
    },
    getPreviousConsolationPrizeWinners() {
      let winners = this.$store.getters.getWinners;
      return winners.filter(
        (winner) =>
          winner.winner_state === "previous" && winner.consolation_prize != null
      );
    },
  },
  components: {
    PrizeButtonBar,
    DateComponent,
  },
  created() {
    this.$store.dispatch("fetchWinners");
  },
};
</script>
