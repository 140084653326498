<template lang="html">
  <div class="vh-100 outer screen" style="text-align: center">
    <div class="container center">
      <div class="row">
        <div
          class="col-lg-8 offset-lg-2 col-md-12 offset-md-0 transparentbox mt-5 mb-5 pb-4 pt-4"
        >
          <!-- <h1 class="text-light">{{ getEmployee.first_name }},</h1> -->
          <h1 class="text-light">Check out your choices! 🛍️</h1>
          <ButtonBar current="checkout" />
          <h3 class="text-light">Please check your details below</h3>
          <h4 class="text-light">
            Winners are announced daily on the draw page.
          </h4>
          <div class="row">
            <div class="input-group mb-3">
              <input
                v-model="getEmployee.first_name"
                type="text"
                class="form-control form-control-lg"
                placeholder="First name"
                aria-label="First name"
                required
              />
              <input
                v-model="getEmployee.last_name"
                type="text"
                class="form-control form-control-lg"
                placeholder="Last name"
                aria-label="Last name"
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="input-group mb-3">
              <input
                v-model="getEmployee.email"
                type="text"
                class="form-control form-control-lg"
                placeholder="Country"
                aria-label="Country"
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="d-grid gap-2">
                <button
                  class="btn btn-lg btn-primary"
                  @click="saveEmployee()"
                  :disabled="!hasValidPersonalData"
                >
                  Save choices
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonBar from "@/components/ButtonBar.vue";

export default {
  name: "CheckoutView",
  data: () => ({}),
  components: {
    ButtonBar,
  },
  computed: {
    getEmployee() {
      return this.$store.getters.getEmployee;
    },
    getPriz() {
      let prizes = this.$store.getters.getPrizes;
      let rPriz = null;
      prizes.forEach((prize) => {
        if (this.getEmployee.choice == prize.number) {
          rPriz = prize;
        }
      });
      return rPriz;
    },
    hasValidPersonalData() {
      let employee = this.getEmployee;
      if (
        employee.first_name == "" ||
        employee.last_name == "" ||
        employee.email == ""
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    saveEmployee() {
      let employee = this.getEmployee;
      this.$store.dispatch("saveEmployee", employee).then((response) => {
        console.log(response);
        this.$router.push("/thankyou");
      });
    },
  },
};
</script>

<style lang="css" scoped>
.rounded-pill-left {
  border-radius: 10rem 0rem 0rem 10rem;
}
.rounded-pill-right {
  border-radius: 0rem 10rem 10rem 0rem;
}
.rounded-pill-middle {
  border-radius: 0rem 0rem 0rem 0rem;
}
</style>
