import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PrizesView from "../views/PrizesView.vue";
import ThankYouView from "../views/ThankYouView.vue";
import VerifyCodeView from "@/views/VerifyCodeView.vue";
import SignUpView from "@/views/SignUpView.vue";
import SignInView from "../views/SignInView.vue";
import DrawView from "../views/DrawView.vue";
import CheckoutView from "@/views/CheckoutView.vue";
import IntroView from "@/views/IntroView.vue";
import ConsolationView from "@/views/ConsolationView.vue";
import WinnersView from "@/views/WinnersView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/verify",
    name: "Verify",
    component: VerifyCodeView,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUpView,
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignInView,
  },
  {
    path: "/intro",
    name: "Intro",
    component: IntroView,
  },
  {
    path: "/prizes",
    name: "Prizes",
    component: PrizesView,
  },
  {
    path: "/consolation",
    name: "Consolation",
    component: ConsolationView,
  },
  {
    path: "/thankyou",
    name: "ThankYouView",
    component: ThankYouView,
  },
  {
    path: "/draw",
    name: "DrawView",
    component: DrawView,
  },
  {
    path: "/winners",
    name: "WinnersView",
    component: WinnersView,
  },
  {
    path: "/checkout",
    name: "CheckoutView",
    component: CheckoutView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
