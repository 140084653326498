<template>
  <div class="container" v-if="getEmployee">
    <div class="row">
      <div
        class="col-lg-10 offset-lg-1 col-md-12 offset-md-0 transparentbox mt-5 pb-4 pb-4 pt-4"
      >
        <h1 class="text-light text-center">Hi {{ getEmployee.first_name }}!</h1>
        <!-- {{ getPrizes }} -->
        <span v-html="getEmployeeIntro" class="text-light text-center"></span>

        <div class="d-flex justify-content-center align-items-center mb-3">
          <button class="btn btn-primary text-center" @click="goToPrizes()">
            Show me the Prizes 🎁
          </button>
          <button
            type="button"
            class="btn btn-secondary ms-3"
            @click="sendToDraw()"
          >
            Prize draw! 🏆
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrizesView",
  created() {
    this.$store.dispatch("fetchAppTexts");
    this.$store.dispatch("fetchPrizes");
    this.populateModal(this.getPrizes[0]);
  },
  data: () => ({
    modal_prize: {},
  }),
  components: {},
  computed: {
    getPrizes() {
      return this.$store.getters.getPrizes;
    },
    getEmployee() {
      return this.$store.getters.getEmployee;
    },
    getEmployeeIntro() {
      let texts = this.$store.getters.getTexts;
      return texts.employee_introduction;
    },
  },
  methods: {
    populateModal(prize) {
      this.modal_prize = prize;
    },
    goToPrizes() {
      this.$router.push("/prizes");
    },
    sendToDraw() {
      this.$router.push("/draw");
    },
  },
};
</script>

<style>
.outer {
  display: flex;
  align-items: center;
}
.center {
  margin: auto;
  padding: 10px;
}
.card {
  border: none;
}
.fadetext {
  height: 50px !important;
  overflow: hidden !important;
  -webkit-mask-image: linear-gradient(180deg, #000 0%, transparent);
  mask-image: linear-gradient(180deg, #000 0%, transparent);
}
.card-body {
  z-index: 1000;
}
</style>
