<template>
  <div class="container" v-if="getEmployee">
    <div class="row">
      <div
        class="col-lg-10 offset-lg-1 col-md-12 offset-md-0 transparentbox mt-5 pb-4 pt-4"
      >
        <h1 class="text-light text-center">Consolation Gifts 📦</h1>

        <ButtonBar current="consolation" />

        <div
          class="row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-4"
        >
          <div class="col" v-for="prize in getPrizes" v-bind:key="prize">
            <div class="card h-100">
              <div class="ratio ratio-4x3">
                <img
                  v-if="prize.image.path !== null"
                  :src="
                    'https://db.sendcloudholidaygifts.com/storage/uploads/' +
                    prize.image.path
                  "
                  class="card-img-top"
                  alt="..."
                  style="object-fit: cover"
                />
              </div>

              <!-- <div class="card-img-overlay" style="text-align: right">
                <h4>
                  <span class="badge bg-primary"> Available </span>
                </h4>
              </div> -->
              <!-- <div class="card-img-overlay">
                <button
                  type="button"
                  class="btn btn-light btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target="#lightboxModal"
                  @click="populateModal(prize)"
                  style="z-index: 999"
                >
                  <i class="bi bi-card-image"></i>
                </button>
              </div> -->
              <div class="card-body pb-3">
                <div class="draw-date text-center">
                  <DateComponent :date="prize.draw_date" />
                </div>
                <h5 class="card-title text-center headline mb-3">
                  {{ prize.name }}
                </h5>
                <span
                  v-html="prize.description"
                  class="bodytext text-center"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PrizeButtonBar current="consolation" />
    </div>
  </div>
</template>

<script>
import ButtonBar from "@/components/ButtonBar.vue";
import DateComponent from "@/components/DateComponent.vue";
import PrizeButtonBar from "@/components/PrizeButtonBar.vue";

export default {
  name: "PrizesView",
  components: {
    ButtonBar,
    DateComponent,
    PrizeButtonBar,
  },
  created() {
    this.$store.dispatch("fetchConsolationPrizes");
  },
  data: () => ({
    modal_prize: {},
  }),
  computed: {
    getPrizes() {
      return this.$store.getters.getConsolationPrizes;
    },
    getEmployee() {
      return this.$store.getters.getEmployee;
    },
    getEmployeeIntro() {
      let texts = this.$store.getters.getTexts;
      return texts.employee_introduction;
    },
  },
  methods: {
    populateModal(prize) {
      this.modal_prize = prize;
    },
  },
};
</script>

<style>
.outer {
  display: flex;
  align-items: center;
}
.center {
  margin: auto;
  padding: 10px;
}
.card {
  border: none;
}
.fadetext {
  height: 50px !important;
  overflow: hidden !important;
  -webkit-mask-image: linear-gradient(180deg, #000 0%, transparent);
  mask-image: linear-gradient(180deg, #000 0%, transparent);
}
.card-body {
  z-index: 1000;
}
.draw-date {
  font-weight: 800;
  color: #c34128;
}
.bodytext p {
  font-size: 0.9em !important;
}
</style>
