<template>
  <div class="col-md-6 offset-md-3 transparentbox mb-3 pb-3 pt-4">
    <h1 class="text-light display-1">No draw Today!</h1>
    <h2 class="text-light display-5">Please visit another time</h2>
  </div>
</template>

<script>
export default {
  name: "InactiveComponent",
};
</script>
