<template>
  <div class="d-flex justify-content-center align-items-center mb-3">
    <button
      v-if="current != 'intro'"
      class="btn btn-primary text-center me-3"
      @click="goToIntro()"
    >
      Instructions 📝
    </button>
    <button
      v-if="current != 'prizes'"
      class="btn btn-primary text-center me-3"
      @click="goToPrizes()"
    >
      Raffle gifts 🎁
    </button>
    <button
      v-if="current != 'consolation'"
      class="btn btn-primary text-center me-3"
      @click="goToConsolationPrizes()"
    >
      Consolation gifts 📦
    </button>
    <button
      v-if="current != 'checkout'"
      class="btn btn-secondary text-center me-3"
      @click="goToCheckOut()"
    >
      Checkout 🛍️
    </button>
  </div>
</template>

<script>
export default {
  name: "ButtonBar",
  props: {
    current: String,
  },
  methods: {
    goToCheckOut() {
      this.$router.push("/checkout");
    },
    goToPrizes() {
      this.$router.push("/prizes");
    },
    goToConsolationPrizes() {
      this.$router.push("/consolation");
    },
    goToIntro() {
      this.$router.push("/intro");
    },
  },
};
</script>
