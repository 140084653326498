<template>
  <div class="vh-100 outer screen" style="text-align: center">
    <div class="container center">
      <div class="row">
        <div class="col-md-6 offset-md-3 transparentbox pb-4 pt-3">
          <h2 class="text-light">Code Verification 🔑</h2>
          <h4 class="text-light">Please enter your code</h4>
          <input
            v-model="code"
            class="form-control field no-focusborder text-uppercase form-control-lg mb-3"
            type="text"
            ref="inputfield"
            placeholder="Code"
            maxlength="6"
            required
          />
          <div class="d-grid gap-2">
            <button
              type="button"
              class="btn btn-primary text-light btn-lg"
              :disabled="isDisabled == true"
              @click="verifyEmployee()"
            >
              Verify
            </button>
          </div>
        </div>
        <div class="col-md-6 offset-md-3">
          <div class="d-grid gap-2">
            <button
              type="button"
              class="btn btn-secondary mt-3"
              @click="goToHome()"
            >
              Home 🏠
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "VerifyCodeView",
  components: {},
  data: () => ({
    email: null,
    code: null,
  }),
  computed: {
    isEmail() {
      if (this.email == null) {
        return true;
      }
      if (this.email) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          return true;
        }
      }
      return false;
    },
    isDisabled() {
      if (this.code) {
        if (
          // /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email) &&
          this.code.length == 6
        ) {
          return false;
        }
      }
      return true;
    },
    validateEmail() {
      let msg;
      if (this.email !== null) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          msg = "";
        } else {
          msg = "Please enter a valid email address";
        }
      }
      return msg;
    },
  },
  created() {},
  methods: {
    verifyEmployee() {
      // First check if there is a record with this code
      axios
        .get(
          "https://sendcloudholidaygifts.com/verify_employee/?code=" +
            this.code.toUpperCase()
        )
        .then(() => {
          // There is a employee with that code, now goto verify the email address
          this.$store.commit("setCode", this.code.toUpperCase());
          // goto email address verification
          this.$router.push("/signin");
        })
        .catch(() => {
          // No employee with that code
          // verify if the code is valid...
          this.verifyCode();
        });
    },
    verifyCode() {
      axios
        .get(
          "https://sendcloudholidaygifts.com/verify_code/?code=" +
            this.code.toUpperCase()
        )
        .then(() => {
          // the code is valid
          this.$store.commit("setCode", this.code.toUpperCase());
          //now goto the form to enter the personal data.
          this.$router.push("/signup");
        })
        .catch(() => {
          // the code does not exist...
          // Act stupid...
        });
    },
    goToHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style>
/* img {
  padding-bottom: 20px;
} */
.outer {
  display: flex;
  align-items: center;
}
.center {
  margin: auto;
  padding: 10px;
}
</style>
