import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";
import router from "../router";

const vuexLocalStorage = new VuexPersistence({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: (state) => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default createStore({
  plugins: [vuexLocalStorage.plugin],
  state: {
    loading: false,
    code: "",
    employee: null,
    chosen: [],
    texts: null,
    prizes: [],
    consolationPrizes: [],
    winners: [],
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getCode(state) {
      return state.code.toUpperCase();
    },
    getEmployee(state) {
      return state.employee;
    },
    getTexts(state) {
      return state.texts;
    },
    getPrizes(state) {
      return state.prizes;
    },
    getConsolationPrizes(state) {
      return state.consolationPrizes;
    },
    getChosen(state) {
      return state.employee.chosen;
    },
    getWinners(state) {
      return state.winners || [];
    },
  },
  mutations: {
    setLoading(state, bool) {
      state.loading = bool;
    },
    setCode(state, value) {
      state.code = value.toUpperCase();
    },
    setEmployee(state, employee) {
      state.employee = employee;
    },
    setTexts(state, texts) {
      state.texts = texts;
    },
    setPrizes(state, prizes) {
      state.prizes = prizes;
    },
    setConsolationPrizes(state, value) {
      state.consolationPrizes = value;
    },
    setChosen(state, value) {
      state.employee.chosen = value;
    },
    updateChosen(state, chosenItems) {
      state.employee.chosen = chosenItems; // Update the chosen array in Vuex state
    },
    setWinners(state, value) {
      state.winners = value;
    },
  },
  actions: {
    saveEmployee(state, data) {
      state.commit("setLoading", true);
      data._state = 1;
      let token = process.env.VUE_APP_WEBAPPTOKEN;
      axios
        .post(
          process.env.VUE_APP_COCKPITURL + "/content/item/employees",
          {
            data: data,
          },
          {
            headers: {
              "api-key": token,
            },
          }
        )
        .then((response) => {
          state.commit("setLoading", false);
          state.commit("setEmployee", response.data);
          return response;
        })
        .catch((err) => console.log(err));
    },
    getEmployee(state, data) {
      state.commit("setLoading", true);
      axios
        .get("https://sendcloudholidaygifts.com/get_employee/", {
          params: {
            email: data.email,
            code: data.code.toUpperCase(),
          },
        })
        .then((response) => {
          state.commit("setLoading", false);
          state.commit("setEmployee", response.data);
          router.push("/intro");
        })
        .catch((err) => console.log(err));
    },
    fetchPrizes(state) {
      state.commit("setLoading", true);
      let token = process.env.VUE_APP_WEBAPPTOKEN;
      axios
        .get(process.env.VUE_APP_COCKPITURL + "/content/items/prizes", {
          params: {
            sort: JSON.stringify({ draw_date: 1 }), // Replace 'field_name' with the actual field to sort by
          },
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          state.commit("setLoading", false);
          state.commit("setPrizes", response.data);
        })
        .catch((err) => console.log(err));
    },
    fetchConsolationPrizes(state) {
      state.commit("setLoading", true);
      let token = process.env.VUE_APP_WEBAPPTOKEN;
      axios
        .get(
          process.env.VUE_APP_COCKPITURL + "/content/items/consolationprizes",
          {
            params: {
              sort: JSON.stringify({ draw_date: 1 }), // Replace 'field_name' with the actual field to sort by
            },
            headers: {
              "api-key": token,
            },
          }
        )
        .then((response) => {
          state.commit("setLoading", false);
          state.commit("setConsolationPrizes", response.data);
        })
        .catch((err) => console.log(err));
    },
    fetchWinners(state) {
      state.commit("setLoading", true);
      let token = process.env.VUE_APP_WEBAPPTOKEN;
      axios
        .get(process.env.VUE_APP_COCKPITURL + "/content/items/winners", {
          params: {
            sort: JSON.stringify({ date: 1 }), // Replace 'field_name' with the actual field to sort by
            populate: 1,
          },
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          state.commit("setLoading", false);
          state.commit("setWinners", response.data);
        })
        .catch((err) => console.log(err));
    },
    fetchAppTexts(state) {
      state.commit("setLoading", true);
      let token = process.env.VUE_APP_WEBAPPTOKEN;
      axios
        .get(process.env.VUE_APP_COCKPITURL + "/content/item/apptext", {
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          state.commit("setLoading", false);
          state.commit("setTexts", response.data);
        })
        .catch((err) => console.log(err));
    },
  },
  modules: {},
});
