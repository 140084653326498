<template>
  <div class="col-md-6 offset-md-3 transparentbox mb-3 pb-3 pt-4">
    <h2 class="text-light display-5">Time until next draw!</h2>
    <h1 class="text-light display-1">{{ countdown }}</h1>
  </div>
</template>

<script>
import CountdownMixin from "@/mixins/CountdownMixin";

export default {
  name: "CountdownCounter",
  mixins: [CountdownMixin],
  created() {
    const serverTimeUrl = "https://sendcloudholidaygifts.com/get_time/"; // Replace with your server's endpoint
    this.startCountdown(serverTimeUrl);
  },
};
</script>
